import { useCallback } from "react";
import { useIntercom } from "react-use-intercom";
import { Link } from "../components/Link";
import { SUPPORT_EMAIL } from "../consts/emails";
import { useUserContext } from "../context/UserContext";
import { useNotifications } from "./useNotifications";

export type UseOpenIntercomReturnType = {
  /**
   * Opens Intercom with an optional message.
   * @param message The message to open Intercom with.
   */
  bootAndShowIntercom(message?: string): void;
  /**
   * Opens Intercom.  Takes no arguments so it can be used as a static callback anywhere.
   */
  bootAndShowIntercomCallback(): void;
};

export const useBootAndShowIntercom = (defaultMessage?: string): UseOpenIntercomReturnType => {
  const { boot, show, showNewMessages } = useIntercom();

  const [{ user }] = useUserContext();

  const { sendNotification } = useNotifications();

  const bootAndShowIntercom = useCallback<UseOpenIntercomReturnType["bootAndShowIntercom"]>(() => {
    boot({
      name: user?.name,
      email: user?.email,
      userId: user?.id,
      hideDefaultLauncher: true,
      customLauncherSelector: "#IntercomHelpButton",
    });

    if (!window.Intercom.booted) {
      sendNotification({
        message: (
          <>
            Couldn't load Intercom. Try sending us an email at{" "}
            <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>. Want to know more? Check out our{" "}
            <Link href="https://help.reclaim.ai/en/articles/6338330">help docs</Link>.
          </>
        ),
        type: "warning",
      });
    } else if (!!defaultMessage) {
      showNewMessages(defaultMessage);
    } else {
      show();
    }
  }, [boot, user, showNewMessages, defaultMessage, show, sendNotification]);

  const bootAndShowIntercomCallback = useCallback(() => bootAndShowIntercom(), [bootAndShowIntercom]);

  return {
    bootAndShowIntercom,
    bootAndShowIntercomCallback,
  };
};
